import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pabxState: {
    sips: [],
    queues: [],
    agents: [],
  },
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'pabx',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true; // Define isLoading como true quando a operação começa
    },
    hasError(state, action) {
      state.isLoading = false; // Define isLoading como false quando há erro
      state.error = action.payload; // Armazena o erro recebido na ação
    },
    updatePabxState(state, action) {
      state.isLoading = false; // Define isLoading como false após atualização
      // Atualiza o estado do PABX com os dados da ação
      state.pabxState = {
        ...state.pabxState, // Mantém os dados existentes
        ...action.payload, // Atualiza com os dados recebidos
      };
    },
  },
});

export const { updatePabxState, startLoading, hasError } = slice.actions;

export default slice.reducer;
