// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
  master: {
    root: path(ROOTS_DASHBOARD, '/master'),
    company: path(ROOTS_DASHBOARD, '/company'),
    companyNew: path(ROOTS_DASHBOARD, '/company/new'),
    companyEdit: path(ROOTS_DASHBOARD, '/company/:uraKey/edit'),
  },
  reporting: {
    estatisticas: path(ROOTS_DASHBOARD, '/reporting/estatisticas'),
    atendimentos: path(ROOTS_DASHBOARD, '/reporting/atendimentos'),
    bi: path(ROOTS_DASHBOARD, '/reporting/bi'),
    // pabx: path(ROOTS_DASHBOARD, '/reporting/pabx'),
  },
  pabx: {
    geral: path(ROOTS_DASHBOARD, '/pabx/geral'),
    graphic: path(ROOTS_DASHBOARD, '/pabx/graphic'),
    generalStatistics: path(ROOTS_DASHBOARD, '/pabx/general-statistics'),
    callRecordings: path(ROOTS_DASHBOARD, '/pabx/call-recording'),
  },
  calls: {
    root: path(ROOTS_DASHBOARD, '/calls'),
    id: path(ROOTS_DASHBOARD, '/calls/:id'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
  },
  contacts: {
    root: path(ROOTS_DASHBOARD, '/contacts'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  chats: {
    root: path(ROOTS_DASHBOARD, '/chats'),
    ura: path(ROOTS_DASHBOARD, '/chats/ura'),
    uraNew: path(ROOTS_DASHBOARD, '/chats/ura/new'),
    uraEdti: path(ROOTS_DASHBOARD, '/chats/ura/:uraKey/edit'),
    troncos: path(ROOTS_DASHBOARD, '/chats/troncos'),
    new: path(ROOTS_DASHBOARD, '/chats/troncos/new'),
    troncokEdit: path(ROOTS_DASHBOARD, '/chats/troncos/:troncoKey'),
  },
  config: {
    root: path(ROOTS_DASHBOARD, '/config'),
    nps: path(ROOTS_DASHBOARD, '/nps'),
    pending: path(ROOTS_DASHBOARD, '/config/pending'),
    messages: path(ROOTS_DASHBOARD, '/config/messages'),
    transhipment: path(ROOTS_DASHBOARD, '/config/transhipment'),
    timeline: path(ROOTS_DASHBOARD, '/config/timeline'),
    group: path(ROOTS_DASHBOARD, '/config/group'),
    groupEdit: path(ROOTS_DASHBOARD, `/config/group/reece-chung/edit`),
    groupNew: path(ROOTS_DASHBOARD, `/config/group/new`),
    users: path(ROOTS_DASHBOARD, '/config/users'),
    contacts: path(ROOTS_DASHBOARD, '/config/contacts'),
  },
  online: {
    root: path(ROOTS_DASHBOARD, '/online/users'),
    view: path(ROOTS_DASHBOARD, '/online/users/:id/view'),
  },
};

export const PATH_DOCS = 'https://google.com.br';
